import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import { Typography } from '@mui/material';
import LifeSideBar from './components/LifeSideBar';

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const LifeSkill= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Container >
      <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              
        <Typography variant='h4' align='center' fontWeight={700}>
           LifeSkill<br/>
        </Typography>
        <Typography  component={'p'} align='justify'>  
          
           <br/>The essence of joyful learning comes through participatory learner–centric activities including games, role play, case studies that are targeted to address the emotional quotient of students. Promotion of healthy lifestyle, nutrition, sanitation, safety and security are the key areas of focus of various life skill activities.
        </Typography>
        <Form/>

            </Grid>
            <Grid item xs={12} md={4}>
             
                <Box marginBottom={4}>
                  <LifeSideBar />
                </Box>
            
              {/* <SidebarNewsletter /> */}
            </Grid>
          </Grid>

      </Container>
{/*    
        <Container maxWidth={0.9} paddingTop={'0 !important'}>
        </Container> */}
    </Main>
  );
};

export default LifeSkill;
